<template>
  <div class="row">
    <div class="col-md-12">
      <div class="bgc-white bd bdrs-3 p-20 mB-20">
        <h2 class="c-grey-900 mB-20" style="display: inline-block">
          {{ $t("orderDetails") }}
        </h2>
        <span style="border-right: 1px solid #ddd; margin: 0 10px"></span>
        <a class="peer" style="display:inline-block"><span :class="getClass(order.status_id)" :style="order.id==1 ? 'color:black' : 'color:white'">{{ getTranslation(order.order_status.ar_name ,order.order_status.en_name) }}</span></a>
        <div v-if="!loading" class="masonry-item w-100">
          <div class="row gap-20">
            <div class="col-md-3">
              <div class="layers bd bgc-white p-20">
                <div class="layer w-100 mB-10">
                  <h6 class="lh-1">{{ $t('clientName') }}</h6>
                </div>
                <div class="layer w-100">
                  <div class="peers ai-sb fxw-nw">
                    <div class="peer">
                      <h4 class="c-grey-900 mB-20">{{order.client_info.name}}</h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-3">
              <div class="layers bd bgc-white p-20">
                <div class="layer w-100 mB-10">
                  <h6 class="lh-1">{{ $t('enteredBy') }}</h6>
                </div>
                <div class="layer w-100">
                  <div class="peers ai-sb fxw-nw">
                    <div class="peer">
                      <h4 class="c-grey-900 mB-20">{{order.employee.name}}</h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-3">
              <div class="layers bd bgc-white p-20">
                <div class="layer w-100 mB-10">
                  <h6 class="lh-1">{{ $t('totalQuantity') }}</h6>
                </div>
                <div class="layer w-100">
                  <div class="peers ai-sb fxw-nw">
                    <div class="peer">
                      <h4 class="c-grey-900 mB-20">{{ order.order_items.reduce((total, obj) => total + obj.quantity, 0) +' '+$t('item')}}</h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-3">
              <div class="layers bd bgc-white p-20">
                <div class="layer w-100 mB-10">
                  <h6 class="lh-1">{{ $t('totalPrice') }}</h6>
                </div>
                <div class="layer w-100">
                  <div class="peers ai-sb fxw-nw">
                    <div class="peer">
                      <h4 class="mB-20 text-success">{{ order.order_items.reduce((total, obj) => total + obj.price, 0) +' '+$t('rial')}}</h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div v-if="!loading" class="col-md-12 mT-20">
            <input type="name" class="form-control" v-model="name" :placeholder="$t('search')" style="font-size: 25px;" />
          </div>
        </div>
        <div id="dataTable_wrapper" class="dataTables_wrapper mT-20">
          <table id="dataTable" class="table table-bordered dataTable table-hover" role="grid" aria-describedby="dataTable_info" style="width: 100%" width="100%" cellspacing="0">
            <thead>
              <tr role="row">
                <th class="sorting" tabindex="0" aria-controls="dataTable" rowspan="1" colspan="1" aria-label="Position: activate to sort column ascending">
                  {{ $t("items") }}
                </th>
                <th class="sorting" tabindex="0" aria-controls="dataTable" rowspan="1" colspan="1" aria-label="Office: activate to sort column ascending">
                  {{ $t("quantity") }}
                </th>
                <th class="sorting" tabindex="0" aria-controls="dataTable" rowspan="1" colspan="1" aria-label="Office: activate to sort column ascending">
                  {{ $t("washType") }}
                </th>
                <th class="sorting" tabindex="0" aria-controls="dataTable" rowspan="1" colspan="1" style="width: 200px" aria-label="Office: activate to sort column ascending">
                  {{ $t("orderType") }}
                </th>
                <th class="sorting" tabindex="0" aria-controls="dataTable" rowspan="1" colspan="1" style="width: 200px" aria-label="Office: activate to sort column ascending">
                  {{ $t("price") }}
                </th>
                <th class="sorting" tabindex="0" aria-controls="dataTable" rowspan="1" colspan="1" style="width: 30px" aria-label="Start date: activate to sort column ascending">
                  {{ $t('edit') }}
                </th>
              </tr>
            </thead>
            <tbody v-if="!loading" v-for="item in getItemsList()" :key="item.id">
              <tr role="row" class="odd">
                <td>
                  {{getTranslation(item.cloth.ar_name , item.cloth.en_name) }}
                </td>
                <td>
                  {{item.quantity +' '+$t('item')}}
                </td>
                <td>
                  {{getTranslation(item.wash_type.ar_name , item.wash_type.en_name) }}
                </td>
                <td>
                  {{getTranslation(item.order_type.ar_name , item.order_type.en_name) }}
                </td>
                <td class="text-success">{{ item.price+' '+$t('rial') }}</td>
                <td v-if="getRole() != 'data_entery'">
                  <a href="javascript:" @click="editOrderItem(item)">
                    <span class="badge rounded-pill bgc-amber-50 c-amber-700">
                      <i class="ti-pencil"></i>
                    </span>
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
          <loading v-show="loading" />
          <p v-show="!order.order_items.length" style="text-align: center">
            {{ $t("noData") }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    props: {
      id: {
        type: String,
        required: true
      },
    },
    data() {
      return {
        loading: true,
        statusLoading: true,
        name: "",
        order: {},
        page: 1,
      };
    },
    created() {
      this.getOrderItems();
    },
    methods: {

      getRole() {
      return sessionStorage.getItem('role');
    },
      getClass(id) {

var text = '';

switch(id) {
    case 1:
        text = 'badge rounded-pill fl-r bg-warning  lh-0 p-10';
        break;
   case 2:
        text = 'badge rounded-pill fl-r bgc-purple-500   lh-0 p-10';
        break;
        case 3:
        text = 'badge rounded-pill fl-r bg-success    lh-0 p-10';
        break;
        case 4:
        text = 'badge rounded-pill fl-r bg-info  lh-0 p-10';
        break;
        default:
        text = 'badge rounded-pill fl-r bg-danger  lh-0 p-10';
        break;

    }

return text
},
      getItemsList() {
        const startsWithSArray = this.order.order_items.filter(element => element.cloth.ar_name.toLowerCase().startsWith(this.name.toLowerCase()) | element.cloth.en_name.toLowerCase().startsWith(this.name.toLowerCase()));
        return startsWithSArray
      },
      getTranslation(ar, en) {
        return localStorage.getItem('lang') == 'ar' ? ar : en;
      },
      changeStatus: function(item) {
        this.statusLoading = true;
        item.user.status_id = item.user.status_id == 1 ? 2 : 1;
        let headers = {
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        };
        this.$http.put("/backend/orderItems/active", {
          orderItems_id: item.id,
          status_id: item.user.status_id
        }, {
          headers
        }).then(function(data) {
            this.statusLoading = false;
            switch (data.status) {
              case 200:
                this.success(this.$t("operationStatusSuccess"));
                break;
            }
          },
          (err) => {
            item.user.status_id = item.user.status_id == 1 ? 2 : 1;
            this.loading = false;
            switch (err.status) {
              case 500:
                this.error(this.$t("operationStatusFailed"));
                break;
              case 401:
                this.error(this.$t("verificationProcessError"));
                localStorage.clear();
                sessionStorage.clear();
                this.$router.push("/login");
                break;
              default:
                this.loading = false;
                this.error(this.$t("unexpectedError"));
            }
          });
      },
      checkForm: function(e) {
        e.preventDefault();
        this.getOrderItems();
      },
      getOrderItems: function() {
        this.loading = true;
        this.$http.get("/backend/orders/getOrderItems", {
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
          params: {
            order_id: this.id,
          },
        }).then(function(data) {
            this.loading = false;
            switch (data.status) {
              case 200:
                this.order = data.body;
                console.log('orderItems : ',this.order);
                break;
            }
          },
          (err) => {
            this.loading = false;
            switch (err.status) {
              case 500:
                this.error(this.$t("canNotAccess"));
                break;
              case 401:
                this.error(this.$t("verificationProcessError"));
                localStorage.clear();
                sessionStorage.clear();
                this.$router.push("/login");
                break;
              default:
                this.error(this.$t("unexpectedError"));
                break;
            }
          });
      },
      // addOrderItem: function () {
      //   const panel1Handle = this.$showPanel({
      //     component: () => import("../../views/orderItems/AddOrderItem.vue"),
      //     openOn: "right",
      //     width: 700,
      //     disableBgClick: true,
      //     props: {
      //       reload:this.getOrderItems,
      //     },
      //   });
      // },
      // editOrderItem(orderItem) {
      //   const panel1Handle = this.$showPanel({
      //     component: () => import("../../views/orderItems/EditOrderItem.vue"),
      //     openOn: "right",
      //     width: 700,
      //     disableBgClick: true,
      //     props: {
      //       orderItem: orderItem,
      //       reload:this.getOrderItems,
      //     },
      //   });
      //   panel1Handle.promise.then((result) => {});
      // },
      error: function(message) {
        this.$toast.error(message, {
          position: "top-right",
          timeout: 3000,
          closeOnClick: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
      },
      warning: function(message) {
        this.$toast.warning(message, {
          position: "top-right",
          timeout: 3000,
          closeOnClick: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
      },
      success: function(message) {
        this.$toast.success(message, {
          position: "top-right",
          timeout: 3000,
          closeOnClick: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
      },
    },
  };
</script>
<style></style>